
























































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetShitiList, AddShiti } from "@/request/storehouse";
import { GetChuxianyuList } from "@/request/mark";
import { GetShuxinglaiyuanOptions } from "@/request/check";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private id: any;
  @Prop()
  private type: any;
  @Prop()
  private detail: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("refreshDataTime", { immediate: true })
  private refreshDataTimeChange() {
    this.getData();
  }
  private cTab: any = "tab1";
  private guanxiArr: any = [];
  private data1: any = {
    attributes: [],
  };
  private data2: any = {
    attributes: [],
  };
  private curSourceData: any = {};
  private ifShowSource: any = false;
  private curSource: any = ""; //添加来源页面的值
  private sources: any = [];
  //   private ifCompose: any = true;
  private guanxiKeys: any = {};
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private addShiti(key: any) {
    const index: any = this.guanxiArr.findIndex((ele: any) => {
      return ele["值名称"] == key;
    });
    this.$emit("addShiti", this.guanxiArr[index]);
  }
  // 处理右键单击的方法
  private handleRightClick(item: any, event: MouseEvent) {
    event.preventDefault(); // 阻止默认的右键菜单显示
    this.delOne(item); // 调用你的删除方法
  }

  // 处理左键双击的方法
  private handleDoubleClick(item: any) {
    if (!this.ifCompose) {
      return;
    }
    this.$emit("editOne", item);
  }
  private delOne(item: any) {
    if (!this.ifCompose) {
      return;
    }
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      this.$emit("delOne", item);
    });
  }
  private addOne(item: any) {
    item.push({
      text: "",
      source: "",
    });
  }
  private getData() {
    this.getShuxing();
    this.getGuanxi();
  }
  private refreshGuanxi() {
    this.data2 = {};
    this.guanxiKeys.forEach((ele: any) => {
      this.data2[ele] = [];
    });
    this.detail.data.forEach((ele: any) => {
      // 只需要处理语义类型
      if (ele.type == "text") {
        // 判断是不是需要展示的语义类型
        let type = ele["语义类型"]["label"];
        const index = this.guanxiKeys.indexOf(type);
        if (index != -1) {
          // 如果有关联实体显示的是关联实体，否则显示的是标注的文本
          let text: any = "";
          if (ele["关联实体"].id) {
            text = ele["关联实体"].name;
          } else {
            text = ele.text;
          }
          // 判断是否已经有了，也就是变相去重
          const i = this.data2[type].findIndex((v: any) => {
            const val = v["关联实体"].id ? v["关联实体"].name : v.text;
            return val === text;
          });
          if (i == -1) {
            this.data2[type].push(ele);
          }
        }
      }
    });
    this.$forceUpdate();
  }
  private deleteSource(item: any) {
    if (!this.ifCompose) {
      return;
    }
    item.source = "";
  }
  private deleteOne(item: any, i: any) {
    item.splice(i, 1);
  }
  private openSource(item: any) {
    this.curSourceData = item;
    this.ifShowSource = true;
    this.curSource = "";
  }
  private addSource() {
    this.curSourceData.source = this.curSource;
    this.getSources();
    this.ifShowSource = false;
  }
  private remoteSource(e: any) {
    this.getSources(e);
  }
  private getShuxing() {
    if (!this.type || !this.id) {
      return;
    }
    const params: any = {
      params: {
        table_name: this.type,
        id: this.id,
        need_attr_split: true,
      },
    };
    GetShitiList(this, params).then((res: any) => {
      this.data1 = res;
    });
  }
  private getGuanxi() {
    if (!this.id) {
      return;
    }
    const params: any = {
      params: {
        entity_id: this.id,
      },
    };
    GetChuxianyuList(this, params).then((res: any) => {
      this.guanxiArr = res;
      this.guanxiKeys = [];
      res.forEach((ele: any) => {
        this.guanxiKeys.push(ele["值名称"]);
      });
      this.refreshGuanxi();
    });
  }
  private getSources(e?: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetShuxinglaiyuanOptions(this, params).then((res: any) => {
      this.sources = res;
    });
  }
  private tijiao() {
    return new Promise((resolve, reject) => {
      this.data1.texts = "其它";
      AddShiti(this, this.data1)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
}
