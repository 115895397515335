

















































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetRenwuList } from "@/request/mark";
import { GetZhuantis } from "@/request/schema";
import { CreateChilTask, GetChildTaskData } from "@/request/common";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import Jingdu from "@/components/biaozhu/jingdu.vue";
import BiaozhuQiefen from "./biaozhu-qiefen.vue";
@Component({
  components: {
    Jingdu,
    BiaozhuQiefen,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  @Prop()
  private parentData: any;
  @Prop()
  private ifShowChild: any;
  @Watch("ifShowChild", { immediate: true })
  private ifShowChildChange() {
    this.ifShow = this.ifShowChild;
    this.getData();
  }
  private ifShow: any = false;
  private data: any = [];
  private ifShowJindu: any = false;
  private jingduId: any = "";
  private zhuantis: any = [];
  private ifShowAddChild: any = false;
  private addData: any = {};
  private contentType: any = ""; //原文内容
  private get user() {
    return this.$store.state.user;
  }
  private typeChange() {
    if (this.contentType == "自定义") {
      this.addData.content = "";
    } else {
      this.addData.content = this.parentData.content;
    }
  }
  private cancel() {
    this.$emit("cancel");
  }
  private changeContent(val: any) {
    this.addData.content = val;
  }
  private openAddChild() {
    console.log(this.parentData);
    this.addData = {
      任务名称: this.parentData["任务名称"] + "-" + (this.data.length + 1),
      content: "",
      // content: this.parentData.content,
      parent_id: this.parentData.id,
      标注者id: this.user.user_id,
      来源: this.parentData["来源"],
      专题类型: this.parentData["专题类型"],
      专题类型列表: this.parentData["专题类型列表"],
      分类: this.parentData["所属分类"],
      文体分类: this.parentData["文体分类"],
    };
    this.ifShowAddChild = true;
    console.log(this.addData);
  }
  private addChildTask() {
    if (!this.addData["任务名称"]) {
      this.$message.warning("请输入任务名称");
      return;
    }
    if (
      !this.addData["专题类型列表"] ||
      this.addData["专题类型列表"].length == 0
    ) {
      this.$message.warning("请选择专题类型");
      return;
    }
    if (!this.addData.content) {
      this.$message.warning("请选择原文内容");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    CreateChilTask(this, this.addData, loading).then((res: any) => {
      this.$message.success("保存成功");
      this.getData();
      this.$emit("updateChild");
      this.ifShowAddChild = false;
    });
  }
  private getData() {
    this.data = [];
    if (!this.parentData.id) {
      return;
    }
    const params: any = {
      params: {
        parent_id: this.parentData.id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetChildTaskData(this, params, loading)
      .then((data: any) => {
        loading.close();
        console.log("4444");
        console.log(data);
        if (data) {
          this.data = data;
        } else {
          this.data = [];
        }

        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private goRead(item: any) {
    if (!this.getIsShowQuanxian("任务标注查看")) {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "任务标注",
      },
    });
  }
  private goBiaozhu(item: any) {
    console.log("跳转任务标注");
    console.log(item);
    if (!this.getIsShowQuanxian("任务标注标注")) {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "任务标注",
      },
    });
    // window.location.reload();
  }
  private openjindu(item: any) {
    if (!this.getIsShowQuanxian("任务标注标注进度")) {
      return;
    }
    this.jingduId = item.id;
    this.ifShowJindu = true;
  }
  private getZhuantis() {
    GetZhuantis(this).then((res: any) => {
      this.zhuantis = [];
      res.forEach((ele: any) => {
        this.zhuantis.push({
          专题类型: ele,
          entity_id: "",
        });
      });
    });
  }
  mounted() {
    this.getZhuantis();
  }
}
