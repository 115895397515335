


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { drawBg, drawText, getStringWidth, splitData } from "@/utils/biaozhu";
import { PostBookContent, createRenwu } from "@/request/storehouse";
import {
  GetBiaozhuyuan,
  GetCategory,
  GetRenwuOption,
  AddRenwu,
} from "@/request/mark";
@Component({})
export default class Name extends Vue {
  @Prop()
  private filter: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.createContent();
  }
  private ifEdit: any = true;
  private lineHeight: any = 30;
  private W: any = 0; // 内容区域宽度
  private contentHeight: any = 100;
  private allString: any = ""; // 所有原文内容字符串
  private strArrData: any = []; // 字符串数组，一个字符为一项
  private defaultLeft: any = 20; // 默认的左间距，给标记留点溢出空间
  private defaultRight: any = 20; // 默认的右间距，给标记留点溢出空间。页面有滚动条时会占据掉15左右，所以右边会比左边多给点
  private lineicon: any = "\n"; // 换行标识符
  private fontSize: any = 16; // 文字大小
  private startPoint: any = {}; // 鼠标按下的坐标
  private endPoint: any = {}; // 鼠标抬起的坐标
  private drawing: any = false; // 是否正在绘制中,为了方便鼠标离开绘制区域时能够及时结束绘制
  private currentSelectText: any = "";
  private numT: any = 0;
  private numL: any = 0;
  private num: any = 0;
  private showNum: any = true;
  private force() {
    this.$forceUpdate();
  }
  private handleMouseDown(e: any) {
    if (!this.ifEdit) {
      return;
    }
    // 禁掉浏览器的选中文字效果
    document.onselectstart = function () {
      return false;
    };
    // 重置数据
    this.startPoint = {};
    this.endPoint = {};
    // 记录开始绘制行为
    this.drawing = true;
    // 记录绘制起点坐标信息
    const line: any = this.getLineNum(e.offsetY);
    const pointY = line * this.lineHeight;
    this.startPoint = {
      x: e.offsetX,
      y: pointY,
      line: line,
    };
    this.numL = e.clientX;
    this.numT = e.clientY;
  }
  private handleMouseMove(e: any) {
    this.showNum = true;
    if (!this.ifEdit) {
      return;
    }
    // 如果不是在绘制中不需要触发计算行为
    if (!this.drawing) {
      return;
    }
    // 当前点的坐标信息
    const line: any = this.getLineNum(e.offsetY);
    const pointY = line * this.lineHeight;
    this.endPoint = {
      x: e.offsetX,
      y: pointY,
      line: line,
    };
    this.numL = e.clientX;
    this.numT = e.clientY;
    this.countNum();
    // 给选中的文本加上背景色
    const dom: any = document.getElementById("selectQ");
    // 清空绘制路径
    const child: any = dom.childNodes;
    // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
    if (child && child.length > 0) {
      for (var i = child.length - 1; i >= 0; i--) {
        dom.removeChild(child[i]);
      }
    }
    // 根据当前的起终点拿出符合的数据，并给这些数据绘制底色
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    this.currentSelectText = "";
    arr.forEach((d: any) => {
      this.currentSelectText += d.text;
      const x: any = d.startX - 2;
      const y: any = d.top - this.fontSize + 2;
      const width: any = d.width + 2;
      drawBg(dom, x, y, width, this.fontSize + 2, "", "#409eff");
    });

    //记录文本标注信息，需要在保存的时候发送给后端保存
    this.$emit("changeContent", this.currentSelectText);
  }
  private handleMouseUp(e: any) {
    this.showNum = false;
    this.num = 0;
    if (!this.ifEdit) {
      return;
    }
    // 清空绘制路径
    // const dom: any = document.getElementById("selectQ");
    // const child: any = dom.childNodes;
    // // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
    // if (child && child.length > 0) {
    //   for (var i = child.length - 1; i >= 0; i--) {
    //     dom.removeChild(child[i]);
    //   }
    // }
    // 如果不是在绘制中，且没有滑动不需要触发计算行为
    if (!this.drawing || !this.endPoint.x) {
      this.drawing = false;
      return;
    }
    this.drawing = false;
    this.showNum = false;
    this.endDrawing();
  }
  private countNum() {
    // 判断当前数据是否超出标注字数限制
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    if (arr.length == 0) {
      return;
    }
    let text = "";
    // 计算选中区域的长度
    arr.forEach((opt: any) => {
      text += opt.text;
    });
    this.num = text.length;
  }
  private endDrawing() {
    // 判断当前数据是否超出标注字数限制
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    if (arr.length == 0) {
      return;
    }
    this.showNum = false;
  }
  // 根据始终点循坏原始数据生成符合条件的集合
  private getSelectArr(startPoint: any, endPoint: any) {
    let startLine: any = startPoint.line;
    let endLine: any = endPoint.line;
    let startX: any = startPoint.x;
    let endX: any = endPoint.x;
    let arr: any = [];
    // 区分起终点
    if (startLine == endLine && startX > endX) {
      startX = endPoint.x;
      endX = startPoint.x;
    }
    if (startLine !== endLine && startLine > endLine) {
      startLine = endPoint.line;
      endLine = startPoint.line;
      startX = endPoint.x;
      endX = startPoint.x;
    }
    // 根据调整后的起终点拿取正确的数组内容，需要区分单行和多行
    this.strArrData.forEach((ele: any, index: any) => {
      if (startLine == endLine) {
        if (
          ele.line == startLine &&
          startX < ele.startX + ele.width / 2 &&
          endX > ele.startX + ele.width / 2
        ) {
          arr.push(ele);
        }
      } else {
        // 多行，需要区分是首行（起点~右边），末行（左边~终点）还是中间行（全部）
        if (
          // 首行
          ele.line == startLine &&
          ele.startX + ele.width / 2 > this.startPoint.x
        ) {
          arr.push(ele);
        } else if (
          ele.line == endLine &&
          ele.startX + ele.width / 2 < this.endPoint.x
        ) {
          arr.push(ele);
        } else if (ele.line > startLine && ele.line < endLine) {
          arr.push(ele);
        }
      }
    });
    return arr;
  }
  // 根据y坐标计算所在行
  private getLineNum(y: any) {
    let line: any = 0;
    this.strArrData.forEach((item: any, itemIndex: any) => {
      if (
        y > item.top - this.fontSize &&
        y < item.top + this.lineHeight - this.fontSize
      ) {
        line = item.line;
      }
    });
    if (y > this.strArrData[this.strArrData.length - 1].top + this.lineHeight) {
      line = this.strArrData[this.strArrData.length - 1].line;
    }
    return line;
  }
  // 生成单个字符串的字符串数组
  private getStrArr() {
    this.allString = this.data.content;
    this.strArrData = [];
    if (this.W < 100) {
      return;
    }
    const arr = splitData([this.allString], this.lineicon);
    let currentLine: any = 1;
    let left: any = this.defaultLeft;
    arr.forEach((ele: any, index: any) => {
      let width = getStringWidth(ele, this.fontSize, this.lineicon);
      const strObj: any = {
        index: index,
        text: ele,
        width: width,
        line: currentLine,
        startX: left, // 开始x轴坐标
        top: currentLine * this.lineHeight,
      };
      this.strArrData.push(strObj);
      left += width;
      if (left > this.W - 60 || ele == this.lineicon) {
        left = this.defaultLeft;
        currentLine += 1;
      }
    });
    this.contentHeight =
      this.strArrData[this.strArrData.length - 1].line * this.lineHeight + 60;
  }
  // 渲染文本内容
  private createText() {
    const element: any = document.getElementById("textsQ");
    // 渲染文本内容
    this.strArrData.forEach((ele: any, i: any) => {
      if (ele.text == this.lineicon) {
        return;
      }
      drawText(element, ele.startX, ele.top, ele.text, this.fontSize, "#333");
    });
  }
  private createContent() {
    this.getStrArr();
    this.createText();
  }
  private mounted() {
    const W = (this.$refs.biaozhuQiefenBox as any).offsetWidth;
    this.W = W - 20;
    this.createContent();
  }
}
