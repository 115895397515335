import { render, staticRenderFns } from "./zhinengbiaozhu.vue?vue&type=template&id=1554cd9c&scoped=true&"
import script from "./zhinengbiaozhu.vue?vue&type=script&lang=ts&"
export * from "./zhinengbiaozhu.vue?vue&type=script&lang=ts&"
import style0 from "./zhinengbiaozhu.vue?vue&type=style&index=0&id=1554cd9c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1554cd9c",
  null
  
)

export default component.exports