





















































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { GetChildTaskData } from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
import Renwuxinxi from "@/components/biaozhu/renwuxinxi.vue";
import Biaozhu from "@/components/biaozhu/biaozhu.vue";
import Jingdu from "@/components/biaozhu/jingdu.vue";
import Shuxing from "../components/shuxing.vue";
import Zhuti from "../components/zhuti.vue";
import Zhinengbiaozhu from "../components/zhinengbiaozhu.vue";
import ChildTaskBiaozhu from "../components/child-task-bioazhu.vue";
import ChildTaskGuanli from "../components/child-task-guanli.vue";
import ChildTaskShenhe from "../components/child-task-shenhe.vue";

import {
  RenwuDetail,
  SaveBiaozhu,
  TijiaoBiaozhu,
  GetBiaozhuyuan,
  Fenpei,
  DeleteRenwu,
  Shenhetongguo,
} from "@/request/mark";
import { GetTupu } from "@/request/tupu";
import { GetRenwuList, GetZhuantiTupu } from "@/request/mark";
import Empty from "@/components/empty.vue";
import CommonTupu from "../components/common-tupu.vue";
import CommonClickTupu from "../components/common-click-tupu.vue";
import { getCurrentTime } from "@/utils/base";
@Component({
  components: {
    MainHeader,
    Renwuxinxi,
    Biaozhu,
    Jingdu,
    Empty,
    Shuxing,
    CommonTupu,
    CommonClickTupu,
    Zhuti,
    Zhinengbiaozhu,
    ChildTaskBiaozhu,
    ChildTaskGuanli,
    ChildTaskShenhe,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  @Watch("$route")
  onRouteChange(to: any, from: any) {
    // 在这里处理路由参数变化
    this.ifShowChild = false;
    if (this.$route.query.kind) {
      this.kind = this.$route.query.kind;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData().then((res: any) => {
        (this.$refs.biaozhu as any).createContent();
      });
    }
    if (this.kind) {
      if (this.kind == "任务审核") {
        const d = localStorage.getItem("rengwushenheFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      } else if (this.kind == "任务标注") {
        const d = localStorage.getItem("biaozhuzhuangtaiFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      } else if (this.kind == "任务管理") {
        const d = localStorage.getItem("renwuguanliFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      } else if (this.kind == "标注结果库") {
        const d = localStorage.getItem("biaozhujieguoFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      }
    }
  }
  private list: any = [];
  private id: any = "";
  private kind: any = "";
  private ifFangda: boolean = false;
  private echartsHeight: number = 300;
  private biaozhuData: any = []; // 标注的内容，后端存储的
  private ifShowJindu: any = false;
  private ifShowSave: any = false;
  private data: any = {};
  private ifShowFenpei: any = false;
  private fenpeiText: any = "分配";
  private fenpeiData: any = {};
  private biaozhuyuan: any = [];
  private ifShowDahui: any = false;
  private ifShowSaveConfirm: any = false;
  private text: any = [];
  private textElse: any = "";
  private toPath: any = "";
  private saveType: any = "";
  private tupuData: any = {};
  private tupuDone: boolean = true;
  private refreshDataTime: any = "";

  private rightTab: any = "基本信息";
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private loading: any = false;
  private tupuType = "通用";
  private zhauntiTupuData: any = {};
  private ifShowChild: any = false;
  private childList: any = [];
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private openParent() {
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: this.data.parent_id,
        kind: this.kind,
      },
    });
  }
  private openChild() {
    this.ifShowChild = true;
  }
  private getChildList() {
    this.childList = [];
    if (!this.data.id) {
      return;
    }
    const params: any = {
      params: {
        parent_id: this.data.id,
      },
    };
    GetChildTaskData(this, params).then((res: any) => {
      if (res) {
        this.childList = res;
      } else {
        this.childList = [];
      }
    });
  }
  private editShitiName(item: any) {
    if (item.ifCustom) {
      const d: any = JSON.parse(JSON.stringify(this.data));
      const index = d.data.findIndex((val: any) => {
        return val.id == item.id;
      });
      d.data[index].text = item.text;
      d.data[index]["更新人"] = this.$store.state.user;
      d.data[index]["更新时间"] = getCurrentTime("yyyy-MM-dd HH:mm:ss");
      this.data = d;
    } else {
      (this.$refs.biaozhu as any).editShitiName(item);
    }
  }
  private editOneShiti(e: any) {
    (this.$refs.biaozhu as any).editOneShiti(e);
  }
  private addZidingyiShiti(e: any) {
    (this.$refs.biaozhu as any).addZidingyiShiti(e);
  }
  private addShiti(key: any) {
    (this.$refs.biaozhu as any).addShiti(key);
  }
  private editOne(item: any) {
    (this.$refs.biaozhu as any).editOne(item);
  }
  private delOneBiaozhi(item: any) {
    (this.$refs.biaozhu as any).deleteOneBiaozhu(item);
  }
  private changeTupuType(val: any) {
    this.tupuType = val;
  }
  private changeData(d: any) {
    this.data = d;
  }
  private shuxingDelOne(e: any) {
    if (e.ifCustom) {
      // 删除手动添加的
      let d: any = JSON.parse(JSON.stringify(this.data));
      // 删除关系标注
      d.data.forEach((item: any, i: any) => {
        if (item.type == "line") {
          if (item.from.id == e.id || item.to.id == e.id) {
            d.data.splice(i, 1);
          }
        }
      });
      // 删除当前标注
      const eIndex: any = d.data.findIndex((val: any) => {
        return val.id == e.id;
      });
      d.data.splice(eIndex, 1);
      this.data = d;
      this.$forceUpdate();
    } else {
      (this.$refs.biaozhu as any).deleteOneBiaozhu(e);
    }
  }
  private shuxingDelMore(e: any) {
    (this.$refs.biaozhu as any).deleteMoreBiaozhu(e);
  }
  private renwuChange(e: any) {
    const str: any = [
      "编码",
      "章节",
      "原文",
      "主题分类",
      "文体分类",
      "释义",
      "关联论述编码",
      "关联论述原文",
      "图谱",
      "医家",
      "年代",
      "源文献",
    ];
    str.forEach((ele: any) => {
      this.data[ele] = e[ele];
    });
  }
  private updateIfCompose(val: any) {
    this.$store.commit("updateIfCompose", val);
  }
  private input() {
    const index = this.text.findIndex((v: any) => {
      return v === "其它";
    });
    if (index === -1) {
      this.text.push("其它");
    }
  }
  private readJingdu() {
    this.ifShowJindu = true;
  }
  private changeBiaozhu(e: any) {
    const data = JSON.parse(JSON.stringify(this.data));
    data.data = e;
    this.data = data;
    if (this.data["专题类型"]) {
      // (this.$refs.shuxing as any).refreshGuanxi();
      // (this.$refs.shuxing as any).getData();
    }
  }
  private triggerEchartsHeight() {
    this.ifFangda = !this.ifFangda;
    this.tupuDone = false;
    // (this.$refs.Echarts as HTMLCanvasElement).style.height = this.ifFangda
    //   ? "600px"
    //   : "400px";
    this.echartsHeight = this.ifFangda ? 600 : 300;
    (this.$refs.Echarts as HTMLElement).setAttribute(
      "height",
      this.echartsHeight + "px"
    );
    this.getData();
  }
  private goBefore() {
    if (this.ifCompose) {
      this.ifShowSave = true;
      this.saveType = "before";
      return;
    }
    const index: any = this.list.findIndex((item: any) => {
      return this.id == item.id;
    });
    // 判断是不是当前页的第一条，如果是则显示上一条
    if (index !== 0) {
      this.id = this.list[index - 1].id;
      this.getData();
      this.$router.replace({
        path: "/main/mark/biaozhu/detail",
        query: {
          id: this.id,
          kind: this.kind,
        },
        replace: true,
      });
    } else {
      // 判断是不是第一页，如果是则给与提示，否则翻页
      if (this.filters.current_page === 1) {
        this.$message.warning("已经是第一条了");
        return;
      } else {
        // 翻页
        this.id = "";
        this.filters.current_page -= 1;
        this.getList("before");
      }
    }
  }
  private goNext() {
    if (this.ifCompose) {
      this.ifShowSave = true;
      this.saveType = "next";
      return;
    }
    const index: any = this.list.findIndex((item: any) => {
      return this.id == item.id;
    });
    // 判断是不是当前页的最后一条，如果是则显示下一条
    if (index != this.list.length - 1) {
      this.id = this.list[index + 1].id;
      this.$router.replace({
        path: "/main/mark/biaozhu/detail",
        query: {
          id: this.id,
          kind: this.kind,
        },
      });
      this.getData();
    } else {
      // 判断是不是最后一页，如果是则给与提示，否则翻页
      if (this.filters.current_page === this.filters.total_page) {
        this.$message.warning("已经是最后一条了");
        return;
      } else {
        // 翻页
        this.id = "";
        this.filters.current_page += 1;
        this.getList();
      }
    }
  }
  private changePageSave() {
    this.ifShowSave = false;
    this.save();
  }
  private openfenpei(val: any) {
    this.fenpeiText = val;
    const params: any = {};
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
      this.ifShowFenpei = true;
    });
  }
  private remoteFenpeiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
    });
  }
  private fenpeisure() {
    if (!this.fenpeiData) {
      this.$message.warning("请选择需要分配的数据标注员");
      return;
    }
    const params: any = {
      id: this.id,
      user_id: this.fenpeiData["标注员"],
    };
    Fenpei(this, params).then((res: any) => {
      this.$message.success(this.fenpeiText + "成功！");
      this.ifShowFenpei = false;
      this.getData();
    });
  }
  private delOne() {
    this.$confirm(
      "您确定要删除该标注任务么？删除后该标注任务相关的数据将全部清除。请慎重操作！",
      "删除",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        const params: any = {
          data: {
            id: this.id,
          },
        };
        DeleteRenwu(this, params).then((res: any) => {
          this.$message.success("删除成功!");
          this.$router.back();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  private getData() {
    return new Promise((resolve, reject) => {
      const params: any = {
        task_id: this.id,
        kind: "任务标注",
      };
      this.loading = true;
      RenwuDetail(this, params)
        .then((res: any) => {
          this.loading = false;
          this.data = res;
          this.getChildList();
          this.refreshDataTime = new Date().getTime();
          // if (this.type == "read") {
          //   const data: any = {
          //     task_id: res.id,
          //   };
          //   this.drawTupu(data);
          // }
          // if (this.kind !== "任务审核" && this.kind !== "任务标注") {
          //   const data: any = {
          //     task_id: res.id,
          //   };
          //   this.drawTupu(data);
          //   this.getZhuantiTupuData(res.id);
          // }
          const data: any = {
            task_id: res.id,
          };
          this.drawTupu(data);
          this.getZhuantiTupuData(res.id);
          resolve(true);
        })
        .catch((err: any) => {
          this.loading = false;
          reject(err);
        });
    });
  }
  private tijiao() {
    // if (this.ifCompose) {
    //   this.$message.warning("请先保存当前数据");
    //   return;
    // }
    if (this.data["标注状态"] == "待标注" && this.data.data.length == 0) {
      this.$message.warning(
        "对不起，您还未开始标注，请标注完后保存，再提交审核。"
      );
      // this.$confirm(
      //   "对不起，您还未开始标注，请标注完后保存，再提交审核。",
      //   "提交审核",
      //   {
      //     customClass: "commonConfirm",
      //   }
      // )
      //   .then(() => {})
      //   .catch(() => {
      //     console.log("取消");
      //   });
    } else {
      this.$confirm(
        "提交后，将不能修改，须等待数据审核员审核，您确定现在需要提交审核么？",
        "提交审核",
        {
          customClass: "commonConfirm",
        }
      )
        .then(() => {
          const data: any = {
            task_id: this.id,
            data: this.data.data,
          };
          const loading = this.$loading({
            lock: true,
            text: "加载中……",
            spinner: "el-icon-loading",
            customClass: "loadClass",
            background: "rgba(255, 255, 255, 0.7)",
          });
          SaveBiaozhu(this, data)
            .then((res: any) => {
              const params: any = {
                params: {
                  task_id: this.id,
                  kind: "提交审核",
                },
              };
              TijiaoBiaozhu(this, params)
                .then((res: any) => {
                  loading.close();
                  this.$message.success("提交审核成功！");
                  this.updateIfCompose(false);
                  this.getData();
                  // this.$router.back();
                })
                .catch(() => {
                  loading.close();
                });
            })
            .catch(() => {
              loading.close();
            });
        })
        .catch(() => {
          console.log("取消");
        });
    }
  }
  private resetData() {
    this.getData().then(() => {
      (this.$refs.biaozhu as any).createContent();
    });
  }
  private clickSave() {
    this.saveType = "保存";
    this.save();
  }
  private returnSave() {
    this.saveType = "离开保存";
    this.save();
  }
  private save() {
    return new Promise((resolve, reject) => {
      // this.data.data = this.data.data.slice(0, 8);
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.data.data.forEach((ele: any) => {
        if (ele.options) {
          delete ele.options;
        }
      });
      const params: any = {
        task_id: this.id,
        data: this.data.data,
        text: "save",
      };
      SaveBiaozhu(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("保存成功！");
          this.updateIfCompose(false);
          if (this.saveType == "离开保存") {
            this.goDefault();
          } else if (this.saveType == "保存") {
            this.getData();
            (this.$refs.biaozhu as any).createContent();
            // this.$router.back();
          } else if (this.saveType == "审核") {
            //不做后续处理
          } else {
            if (this.saveType == "before") {
              this.goBefore();
            } else {
              this.goNext();
            }
          }
          resolve(true);
          return;
          if (this.data["专题类型"]) {
            (this.$refs.shuxing as any).tijiao().then((res: any) => {
              this.$message.success("保存成功！");
              this.updateIfCompose(false);
              if (this.saveType == "离开保存") {
                this.goDefault();
              } else if (this.saveType == "保存") {
                this.getData();
                // this.$router.back();
              } else {
                if (this.saveType == "before") {
                  this.goBefore();
                } else {
                  this.goNext();
                }
              }
            });
          } else {
            this.$message.success("保存成功！");
            this.updateIfCompose(false);
            if (this.saveType == "离开保存") {
              this.goDefault();
            } else if (this.saveType == "保存") {
              this.getData();
              // this.$router.back();
            } else {
              if (this.saveType == "before") {
                this.goBefore();
              } else {
                this.goNext();
              }
            }
          }
        })
        .catch(() => {
          loading.close();
          reject();
        });
    });
  }
  // 跳转目标页面
  private goDefault() {
    this.updateIfCompose(false);
    this.$router.push({
      path: this.toPath,
    });
  }
  private goTongguo() {
    if (this.ifCompose) {
      this.saveType = "审核";
      this.save().then(() => {
        (this.$refs.biaozhu as any).createContent();
        this.tongguo();
      });
    } else {
      this.tongguo();
    }
  }
  private tongguo() {
    this.$confirm(
      "审核通过后，该文本标注内容即可进入标注结果库，您确定审核通过么？",
      "审核通过",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        const params: any = {
          task_id: this.id,
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        Shenhetongguo(this, params)
          .then((res: any) => {
            loading.close();
            this.$message.success("审核通过成功！");
            this.updateIfCompose(false);
            this.$store.commit("updateIfCompose", false);
            this.getData();
            // this.$router.back();
          })
          .catch(() => {
            loading.close();
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  private goDahui() {
    if (this.ifCompose) {
      this.saveType = "审核";
      this.save().then(() => {
        (this.$refs.biaozhu as any).createContent();
        this.ifShowDahui = true;
      });
    } else {
      this.ifShowDahui = true;
    }
  }
  private dahui() {
    if (this.text.length === 0) {
      this.$message.warning("请选择需要修改的建议");
      return;
    }
    const index = this.text.findIndex((v: any) => {
      return v === "其它";
    });
    let result: any = [];
    if (index === -1) {
      result = this.text;
    } else {
      if (this.textElse) {
        result = this.text;
        result[index] = "其它，" + this.textElse;
      } else {
        result = this.text;
      }
    }
    const params: any = {
      params: {
        task_id: this.id,
        kind: "打回修改",
        text: result.join("、"),
      },
    };
    TijiaoBiaozhu(this, params).then((res: any) => {
      this.$message.success("打回修改成功！");
      this.ifShowDahui = false;
      this.updateIfCompose(false);
      this.$store.commit("updateIfCompose", false);
      this.getData();
      // this.$router.back();
    });
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    if (this.ifCompose) {
      this.toPath = to.fullPath;
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      // 禁掉浏览器的选中文字效果
      document.onselectstart = function () {
        return true;
      };
      // 禁掉浏览器的点击右键出现菜单功能
      document.oncontextmenu = function () {
        return true;
      };
      next(true);
    }
  }
  private drawTupu(d: any) {
    const params: any = {
      params: {
        entity_id: d.entity_id,
        entity_name: d.entity_name,
        entity_type: d.entity_type,
        task_id: d.task_id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetTupu(this, params, loading).then((data: any) => {
      loading.close();
      this.tupuData = data;
      return;
      this.tupuDone = true;
      this.$nextTick().then(() => {
        data.nodes.forEach((ele: any, index: any) => {
          if (ele.type === "目标") {
            ele.symbol = "roundRect";
            ele.symbolSize = 25;
          } else if (ele.type === "实体") {
            ele.symbolSize = 20;
          } else {
            ele.symbolSize = 1;
          }
        });
        const Chart: any = echarts.init(
          this.$refs.Echarts as HTMLCanvasElement
        );
        const options: any = {
          color: this.colors,
          title: {
            text: "",
            subtext: "",
            top: "bottom",
            left: "right",
          },
          tooltip: {},
          legend: [
            {
              // selectedMode: 'single',
              data: data.categories.map(function (a: any) {
                return a.name;
              }),
            },
          ],
          animationDuration: 1500,
          animationEasingUpdate: "quinticInOut",
          series: [
            {
              name: "",
              type: "graph",
              layout: "force",
              data: data.nodes,
              links: data.links,
              categories: data.categories,
              zoom: this.ifFangda ? 6 : 3,
              roam: true,
              label: {
                show: true,
                position: "inside",
                formatter: "{b}",
              },
              edgeLabel: {
                show: true,
                formatter: (res: any) => {
                  return res.data.name;
                },
              },
              edgeSymbol: ["", "arrow"],
              lineStyle: {
                color: "source",
                width: 1,
                curveness: 0.1,
              },
              emphasis: {
                show: false,
                focus: "adjacency",
                lineStyle: {
                  width: 6,
                },
              },
            },
          ],
        };
        Chart.setOption(options, true);
        // 点击事件
        Chart.off("click");
        Chart.on("click", (p: any) => {
          const data: any = {
            entity_id: p.id,
            entity_name: p["name"],
            entity_type: p["category"],
          };
          // this.drawTupu(data);
        });
      });
    });
  }
  getList(val?: any) {
    if (this.kind === "任务审核") {
      const params: any = {
        params: {
          search: this.filters.search,
          标注状态: this.filters["审核状态"],
          user_id: this.filters["标注员"],
          category: this.filters["分类"],
          order_field: "更新时间",
          order_value: this.filters.sort_value,
          current_page: this.filters.current_page,
          page_num: this.filters.page_count,
          kind: "任务审核",
        },
      };
      localStorage.setItem("rengwushenheFilter", JSON.stringify(this.filters));
      this.loading = true;
      GetRenwuList(this, params)
        .then((data: any) => {
          this.loading = false;
          this.list = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          this.refresh(val);
        })
        .catch(() => {
          this.loading = false;
        });
    } else if (this.kind === "任务标注") {
      const params: any = {
        params: {
          search: this.filters.search,
          标注状态: this.filters["标注状态"],
          category: this.filters["分类"],
          order_field: "更新时间",
          order_value: this.filters.sort_value,
          current_page: this.filters.current_page,
          page_num: this.filters.page_count,
          kind: "任务标注",
        },
      };
      localStorage.setItem(
        "biaozhuzhuangtaiFilter",
        JSON.stringify(this.filters)
      );
      this.loading = true;
      GetRenwuList(this, params)
        .then((data: any) => {
          this.loading = false;
          this.list = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          this.refresh(val);
        })
        .catch(() => {
          this.loading = false;
        });
    } else if (this.kind === "任务管理") {
      const params: any = {
        params: {
          search: this.filters.search,
          分配状态: this.filters["分配状态"],
          标注状态: this.filters["标注状态"],
          category: this.filters["分类"],
          user_id: this.filters["标注员"],
          order_field: "创建时间",
          order_value: this.filters.sort_value,
          current_page: this.filters.current_page,
          page_num: this.filters.page_count,
          kind: "任务管理",
        },
      };
      localStorage.setItem("renwuguanliFilter", JSON.stringify(this.filters));
      this.loading = true;
      GetRenwuList(this, params)
        .then((data: any) => {
          this.loading = false;
          this.list = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          this.refresh(val);
        })
        .catch(() => {
          this.loading = false;
        });
    } else if (this.kind === "标注结果库") {
      const params: any = {
        params: {
          search: this.filters.search,
          user_id: this.filters["标注员"],
          category: this.filters["分类"],
          order_field: "更新时间",
          order_value: this.filters.sort_value,
          current_page: this.filters.current_page,
          page_num: this.filters.page_count,
          kind: "标注结果库",
        },
      };
      localStorage.setItem("biaozhujieguoFilter", JSON.stringify(this.filters));
      this.loading = true;
      GetRenwuList(this, params)
        .then((data: any) => {
          this.loading = false;
          this.list = data.data;
          this.filters.total_count = data.total_count;
          this.filters.total_page = data.total_page;
          this.refresh(val);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
  private refresh(val?: any) {
    if (!this.id) {
      if (val == "before") {
        this.id = this.list[this.list.length - 1].id;
      } else {
        this.id = this.list[0].id;
      }
      this.getData();
      this.$router.replace({
        path: "/main/mark/biaozhu/detail",
        query: {
          id: this.id,
          kind: this.kind,
        },
      });
    } else {
      this.getData();
    }
  }
  private getZhuantiTupuData(id: any) {
    const params: any = {
      params: {
        task_id: id,
      },
    };
    GetZhuantiTupu(this, params).then((res: any) => {
      this.zhauntiTupuData = res;
    });
  }
  mounted() {
    if (this.$route.query.kind) {
      this.kind = this.$route.query.kind;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      // this.getData();
    }
    if (this.kind) {
      if (this.kind == "任务审核") {
        const d = localStorage.getItem("rengwushenheFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      } else if (this.kind == "任务标注") {
        const d = localStorage.getItem("biaozhuzhuangtaiFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      } else if (this.kind == "任务管理") {
        const d = localStorage.getItem("renwuguanliFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      } else if (this.kind == "标注结果库") {
        const d = localStorage.getItem("biaozhujieguoFilter") || "";
        if (d) {
          this.filters = JSON.parse(d);
        }
        this.getList();
      }
    }
  }
}
