












































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetSechemaList, GetEntity } from "@/request/mark";
import { AddShiti } from "@/request/storehouse";
@Component({})
export default class Name extends Vue {
  @Prop()
  private dialogData: any;
  @Prop()
  private data: any;
  @Prop()
  private index: any;
  @Watch("data", { immediate: true })
  private dataDataChange() {
    this.item = JSON.parse(JSON.stringify(this.data));
    // 获取下拉选项
    this.searchGuanxiOptions("");
    this.searchYuyiOptions("");
    this.getEntityOption("");
  }
  private item: any;
  private guanxiList: any = [];
  private yuyileixingList: any = [];
  private entityOptions: any = [];
  private ifShowZidingyiShiti: any = false;
  private zidingyiName: any = "";
  private changeArrow(val: any) {
    if (!this.item.ifCustom) {
      return;
    }
    this.item.direction = val;
    this.valueChange();
  }
  private openAddshiti() {
    if (!this.item["语义类型"] || !this.item["语义类型"]._id) {
      this.$message.warning("请先选择语义类型");
      return;
    }
    this.ifShowZidingyiShiti = true;
    this.zidingyiName = "";
  }
  private saveAdd() {
    if (!this.zidingyiName) {
      this.$message.warning("请输入实体名称");
      return;
    }
    const params: any = {
      语义id: this.item["语义类型"]._id,
      实体名称: this.zidingyiName,
      是否临时: true,
    };
    AddShiti(this, params).then((res: any) => {
      this.ifShowZidingyiShiti = false;
      //刷新选项
    });
  }
  private delOne() {
    this.$confirm("您确定要删除么？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      this.$emit("delOne", this.index);
    });
  }
  private valueChange(type?: any) {
    if (type == "语义类型") {
      this.item["关联实体"] = [];
      this.entityOptions = [];
    }
    this.$forceUpdate();
    this.$emit("change", this.item, this.index);
  }
  private searchGuanxiOptions(e: any) {
    const params: any = {
      params: {
        search: e,
        type: "关系类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.guanxiList = data;
      // 如果当前结果不在选项里需要添加进去
      if (
        this.data.data &&
        this.data.data["关系类型"] &&
        this.data.data["关系类型"].id
      ) {
        const index: any = this.guanxiList.findIndex((val: any) => {
          return val.id == this.data.data["关系类型"].id;
        });
        if (index == -1) {
          this.guanxiList.unshift(this.data.data["关系类型"]);
        }
      }
    });
  }
  private searchYuyiOptions(e: any) {
    const params: any = {
      params: {
        search: e,
        type: "语义类型",
      },
    };
    GetSechemaList(this, params).then((data: any) => {
      this.yuyileixingList = data;
      // 如果当前结果不在选项里需要添加进去
      if (this.data && this.data["语义类型"] && this.data["语义类型"]._id) {
        const index: any = this.yuyileixingList.findIndex((val: any) => {
          return val._id == this.data["语义类型"]._id;
        });
        if (index == -1) {
          this.yuyileixingList.unshift(this.data["语义类型"]);
        }
      }
    });
  }
  private getEntityOption(search: any) {
    if (!this.item["语义类型"] || !this.item["语义类型"].label) {
      this.entityOptions = [];
      return;
    }
    const params: any = {
      params: {
        kind: this.item["语义类型"].label,
        search: search || "",
      },
    };
    GetEntity(this, params).then((res: any) => {
      this.entityOptions = res;
      // 如果当前结果不在选项里需要添加进去
      if (this.data["关联实体"].length > 0) {
        this.data["关联实体"].forEach((ele: any) => {
          const index: any = this.entityOptions.findIndex((val: any) => {
            return val.id == ele.id;
          });
          if (index == -1) {
            this.entityOptions.unshift(ele);
          }
        });
      }
    });
  }
}
