import { render, staticRenderFns } from "./relation-select.vue?vue&type=template&id=e3a725e2&scoped=true&"
import script from "./relation-select.vue?vue&type=script&lang=ts&"
export * from "./relation-select.vue?vue&type=script&lang=ts&"
import style0 from "./relation-select.vue?vue&type=style&index=0&id=e3a725e2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3a725e2",
  null
  
)

export default component.exports