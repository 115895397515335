





















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { GetChildTaskData } from "@/request/common";
import { DeleteRenwu } from "@/request/mark";
import Jingdu from "@/components/biaozhu/jingdu.vue";
@Component({
  components: {
    Jingdu,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  @Prop()
  private parentData: any;
  @Prop()
  private ifShowChild: any;
  @Watch("ifShowChild", { immediate: true })
  private ifShowChildChange() {
    this.ifShow = this.ifShowChild;
    this.getData();
  }
  private ifShow: any = false;
  private data: any = [];
  private ifShowJindu: any = false;
  private jingduId: any = "";
  private cancel() {
    this.$emit("cancel");
  }
  private getData() {
    this.data = [];
    if (!this.parentData.id) {
      return;
    }
    const params: any = {
      params: {
        parent_id: this.parentData.id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetChildTaskData(this, params, loading)
      .then((data: any) => {
        loading.close();
        if (data) {
          this.data = data;
        } else {
          this.data = [];
        }

        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 查看
   */
  private goRead(item: any) {
    if (!this.getIsShowQuanxian("任务管理查看")) {
      return;
    }
    if (item["智能标注状态"] == "运行中") {
      return;
    }
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/mark/biaozhu/detail",
      query: {
        id: item.id,
        kind: "任务管理",
        type: "read",
      },
    });
  }
  private openjindu(item: any) {
    if (!this.getIsShowQuanxian("任务管理标注进度")) {
      return;
    }
    this.jingduId = item.id;
    this.ifShowJindu = true;
  }
  private delOne(item: any) {
    if (!this.getIsShowQuanxian("任务管理删除")) {
      return;
    }
    this.$confirm(
      "您确定要删除该标注任务么？删除后该标注任务相关的数据将全部清除。请慎重操作！",
      "删除",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        const params: any = {
          data: {
            id: item.id,
          },
        };
        DeleteRenwu(this, params).then((res: any) => {
          this.$message.success("删除成功!");
          this.$emit("updateChild");
          this.getData();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
