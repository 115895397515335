



































import { Component, Vue, Prop } from "vue-property-decorator";
import { ChangeZhinengBiaozhu, DeleteZhinengBiaozhu } from "@/request/mark";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private yunxing() {
    console.log("运行");
    const params: any = {
      task_id: this.data.id,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    ChangeZhinengBiaozhu(this, params, loading).then(() => {
      loading.close();
      this.$confirm(
        "AI运行需要一段时间，您可以关闭当前页面或执行其它操作，待AI运行结束，即可查看智能标注结果。",
        "",
        {
          customClass: "commonConfirm",
          showCancelButton: false,
          showClose: false,
        }
      ).then(() => {
        // this.$emit("getData");
        this.$router.back();
      });
    });
  }
  private reset() {
    console.log("清空");
    this.$confirm("您确定需要清空当前的智能标注痕迹么？", "清空", {
      customClass: "commonConfirm",
    }).then(() => {
      console.log("确定");
      const params: any = {
        data: {
          task_id: this.data.id,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      DeleteZhinengBiaozhu(this, params, loading).then((res: any) => {
        this.$emit("resetData");
      });
    });
  }
}
